body {
    margin: 0
}
h1, h2, h3, h4, h5, h6, span, div, button, a {
    font-family: 'Poppins' !important;
    font-weight: normal;
}

.react-multi-carousel-item{
    visibility: hidden;
}

.react-multi-carousel-item--active{
    visibility: unset;
}